.text-decoration-none {
    color: white;
}
.text-decoration-none:hover {
    color: yellow;
}
.sidebar {
    position:fixed;
    top:0;
    right:0;
    width:100%;
    height:100%;
    opacity:0.9;
    z-index:1000;
    background-color:black;
}